.hoox-press {
    padding: 32px 0;
}

.hoox-press__headline {
    color: var(--hoox-color-slate-secondary);
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
    margin-bottom: 32px;
}

.hoox-press__items {
    display: flex;
    align-items: center;
    gap: 64px;
    width: max-content;
}

.hoox-press__items .c-image {
    max-width: unset;
}

@media (max-width: 1023px) {
    .hoox-press__content {
        position: relative;
        height: 38px;
        padding: 0;
    }

    .loop-trust-animation {
        width: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        z-index: 1;
        height: 38px;
        overflow: visible;
        position: absolute;
        top: 0;
        left: 0;
        max-width: unset;
        gap: 64px;
    }

    .hoox-press__wrapper {
        height: 100%;
        line-height: 1;
        white-space: nowrap;
        display: flex;
        align-items: center;
        animation: loop-marquee 40s linear infinite;
    }
    .hoox-press-lp-3 {
        padding: 26px 0;
    }
}

    .hoox-press-lp-3 .hoox-wrapper .hoox-press__headline {
        margin-bottom: 13px;
        font-size: 22px;
    }

@media (min-width: 1024px) {
    .hoox-press__headline {
        font-size: 25px;
        margin-bottom: 0;
    }

    .hoox-press-lp-3 .hoox-wrapper .hoox-press__headline {
        font-size: 24px;
    }

    .hoox-press__wrapper {
        display: none;
    }

    .hoox-press__wrapper:first-of-type {
        display: block;
    }

    .hoox-press .hoox-wrapper {
        display: flex;
        align-items: center;
        gap: 64px;
        justify-content: center;
    }

    .hoox-press-lp-3 .hoox-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 27px;
        justify-content: center;
    }

    .hoox-press-lp-3 .hoox-wrapper .hoox-press__headline {
        margin-bottom: 0;
    }

    .hoox-press__items {
        gap: 64px;
    }

    .hoox-press__content::before,
    .hoox-press__content::after {
        display: none;
    }
}



/* Animation */

@keyframes loop-marquee {
    0% {
        transform: translateZ(0);
        visibility: visible;
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

